<template>
  <v-dialog v-model="updateData" width="400" persistent>
    <v-card style="overflow: hidden">
      <!--<v-row>
        <v-spacer />
        <img src="@/assets/logo.svg" class="ml-4" />
        <v-spacer />
      </v-row>-->
      <v-card-title>{{ this.$t("refresh_info") }}</v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn elevation="0" height="25px" @click="refresh()">{{
          this.$t("refresh")
        }}</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppRefresh",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    //this.$store.dispatch("auth/getNotifications");
    //this.$store.dispatch("auth/checkActive");
  },
  data: () => ({}),

  computed: {
    ...mapState("app", ["update"]),
    updateData: {
      get() {
        return this.update;
      },
      set(value) {
        this.setUpdate(value);
      },
    },
  },

  methods: {
    ...mapMutations("app", {
      setUpdate: "SET_UPDATE",
    }),
    refresh() {
      this.setUpdate(false);
      window.location.href = window.location.origin;
    },
  },
};
</script>